import React from 'react';
import { Link } from 'react-router-dom' ;

import './NavBar.css'
import {MenuItems} from './MenuItems'
import {Button} from './Button'


    class NavBar extends React.Component {
        state = { clicked: false }

        handleClick = () => {
            this.setState({ clicked: !this.state.clicked })
        }

        render () {
            return(
                <nav className="NavBarItems">
                    <h1 className = "navBarName">Eric Chianti</h1>
                    <div className = "menuIcon" onClick={this.handleClick}>
                        <i className = {this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
                    </div>
                    <ul className = {this.state.clicked ? 'navMenu active' : 'navMenu'}>
                        {MenuItems.map((item, index) => {
                            return (
                                <li key={index}>
                                    {/* <a className = {item.cName} href = {item.url}>
                                        {item.title}
                                    </a> */}
                                    <Link to={item.url} className={item.cName} onClick={this.handleClick}>{item.title}</Link>
                                </li>
                            )
                            })
                        }
                        <li>
                            <a className = "navLinks-Mobile" href = "mailto:eric@ericchianti.com">Contact Me</a>
                        </li>
                    </ul>
                    <a href = "mailto:eric@ericchianti.com"><Button>Contact Me</Button></a>
                </nav>
            )
        }
    }
export default NavBar;