import { motion } from 'framer-motion'

import style from './Education.module.css'

import UH from '../Images/UH.png'

function Education () {
    document.title = "Eric Chianti: Education"
    return (
        <motion.div
        className = {style.content}
        initial = {{ opacity: 0 }}
        animate = {{ opacity: 1 }}
        exit = {{ opacity: 0 }}>
            <br/>
            <img className = {style.image} src={UH} alt="" />
            <br/>
            <p className = {style.paragraph}>
                Starting in January 2018 and graduating in May 2021, I completed my B.S. in Mathematics with a financial focus and Computer Science at the University of Houston.  My favorite classes included Stochastic Processes and Database Systems.  I look forward to building on the skills I've acquired in my studies in my future endeavors.
            </p>
        </motion.div>
    );
}

export default Education