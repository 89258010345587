import { motion } from 'framer-motion'

import styles from './Skills.module.css'
import { SL } from './SL'

function Skills () {
    document.title = "Eric Chianti: Skills/Languages"
    return (
        <motion.div
        className = {styles.Content}
        initial = {{ opacity: 0 }}
        animate = {{ opacity: 1 }}
        exit = {{ opacity: 0 }}
        // transition = {{
        //     duration: 0.2,
        //     // ease: "ease-in",
        // }}
        >
            <br/>
            <h1 className = {styles}>Skills and Languages</h1>
            <br/>
            <div className ={styles.ContentInner}>
                {
                    SL.map((item, index) => {
                        return (
                            <div>
                                <h2 className = {styles}>{ item.skill }</h2>
                                <div className = {styles.ContentInner}>
                                    {item.detail}
                                </div>
                            </div>
                        )
                    }
                    )
                }
            </div>
        </motion.div>
    )
}

export default Skills


/* 
<div className = {styles.ContentInner}>
                <h2 className = {styles}>React JS, HTML, CSS, JavaScript, and PHP</h2>
                    <div className = {styles.ContentInner}>
                        This website was written in React JS using HTML, CSS, and JavaScript elements.  I've also completed projects using various combinations of these languages, including a web-based front-end for a database system
                    </div>
                <h2 className = {styles}>MySQL/SQL</h2>
                    <div className = {styles.ContentInner}>
                        I've completed multiple projects using MySQL databases including the project mentioned above, in which my team and I created a library management database.  I am extremely confident in database design and applications.
                    </div>
            </div>
*/