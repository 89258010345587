import { motion } from 'framer-motion'

import portrait from '../Images/IMG_1183.jpg'

import styles from './About.module.css'

function About() {
    document.title = "Eric Chianti"
    return (
        <motion.div 
        className = {styles.Content}
        initial = {{ opacity: 0 }}
        animate = {{ opacity: 1 }}
        exit = {{ opacity: 0 }}>
            <br/>
            <img className = {styles.portrait} src={portrait} alt="" />
            <br/>
            <p className = {styles.paragraph}>
                My name is Eric Chianti.  I am a mathematician and computer scientist with a passion for software engineering and statistics.  As a recent graduate of the University of Houston, I am seeking a challenging role implementing software engineering concepts, working collaboratively with other engineers, and building leadership skills.
            </p>
        </motion.div>
    );
}

export default About;