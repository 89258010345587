export const SL = [
    {
        skill: "React JS, HTML, CSS, JavaScript, and PHP",
        detail: "This website was written in React JS using HTML, CSS, and JavaScript elements.  I've also completed projects using various combinations of these languages, including a web-based front-end for a database system."
    },
    {
        skill: "MySQL/SQL",
        detail: "I've completed multiple projects using MySQL databases including the project mentioned above, in which my team and I created a library management database.  I am extremely confident in database design and applications."
    },
    {
        skill: "Bash/UNIX/Linux",
        detail: "Used daily for years; extremely fluent with Linux and macOS command line tools."
    },
    {
        skill: "C, C++, C#, Objective-C, and Swift",
        detail: "Completed many projects both in my studies in and my free time in each of these languages"
    },
    {
        skill: "Java",
        detail: "Used extensively in academia and applied in personal projects."
    },
    {
        skill: "Python",
        detail: "Used extensively in academia and applied in personal projects."
    },
    {
        skill: "Git",
        detail: "I've used Git on almost every project I've ever completed, including in groups to facilitate collaboration and isolate issues in modular integration."
    },
    {
        skill: "LaTeX",
        detail: "I've used LaTeX extensively through my studies to typeset notes and to complete other typesetting projects, through which I've accumulated a great deal of experience."
    }
]