import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'

import './App.css';
import NavBar from './components/NavBar/NavBar';
import About from './components/Pages/About'
import Education from './components/Pages/Education'
import Skills from './components/Pages/Skills'

function App() {
  return (
      <Router>
        <div className = "App">
          <NavBar />
          <div className = "Content">
            <AnimatePresence>
              <Switch>
                <Route exact path="/education">
                  <Education />
                </Route>
                <Route exact path="/skills-and-languages">
                  <Skills />
                </Route>
                <Route path="/">
                  <About />
                </Route>
              </Switch>
            </AnimatePresence>
          </div>
        </div>
      </Router>
  );
}

export default App;
